<template>
  <div class="hero">
    <div class="navbar">
      <img src="../assets/Logo.png" alt="Tekpay Logo" class="logo">
      <ul class="nav-links">
        <li><a href="#">About</a></li>
        <li><a href="#">FAQ</a></li>
        <li><a href="#">Contact Us</a></li>
        <li><a href="#">Become an Agent</a></li>
        <li><a href="#">Terms</a></li>
      </ul>
      <button class="download-btn">Download App</button>
      <div class="mobile-menu-icon" @click="toggleMenu">
        &#9776;
      </div>
    </div>
    <div class="mobile-nav" :class="{ 'open': menuOpen }">
      <ul>
        <li><a href="#">About</a></li>
        <li><a href="#">FAQ</a></li>
        <li><a href="#">Contact Us</a></li>
        <li><a href="#">Become an Agent</a></li>
        <li><a href="#">Terms</a></li>
      </ul>
    </div>
    <div class="hero-content">
      <div class="hero-content-container">
        <div class="text-content">
          <div class="first-text">
            <div class="bordered-text"><span class="second-border">New</span> Tekpay is Live in Nigeria</div>
          </div>
          <h1>The ultimate bill payment solution.</h1>
          <p>Paying bills is much easier with our platform.</p>
          <div class="download-buttons">
            <a href="link_to_google_play" target="_blank">
              <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Get it on Google Play" class="google-play-badge">
            </a>
            <a href="link_to_app_store" target="_blank">
              <img src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg" alt="Download on the App Store" class="app-store-badge">
            </a>
          </div>
        </div>
        <div class="image main-hero-image">
          <img src="../assets/hero-mock.png" alt="Tekpay Hero Image">
        </div>
      </div>
    </div>
    <div class="image secondary-hero-image">
      <img src="../assets/hero-img.png" alt="Tekpay Hero Image">
    </div>
  </div>
  <div class="brands-section">
  <h2>Over <span class="number">30+</span> service providers <br> <span class="h2br">brands connected to <span class="tekpay">TekPay</span></span></h2>
  <p>making the lives of users much easier</p>
  <div class="brand-image-banner">
    <img src="../assets/brands.png" alt="Brands connected to TekPay">
  </div>
</div>

<!-- Services -->
<div class="services-section">
  <div class="column-a">
    <h2>Our Services</h2>
    <p>Pay all your bills at once, without leaving your home with TekPay’s comprehensive range of services.</p>
  </div>
  <div class="column-b">
    <div class="service-item">
      <img src="../assets/icons/airtime.png" alt="Airtime Topup">
      <div class="service-text">
        <h3>Airtime Topup</h3>
        <p>Stay connected with your loved ones and top up your airtime with TekPay. It’s quick, easy and available 24/7.</p>
      </div>
    </div>
    <div class="service-item">
      <img src="../assets/icons/electricity.png" alt="Electricity Bills">
      <div class="service-text">
        <h3>Electricity Bills</h3>
        <p>Never miss a due date or wait in long lines again. Pay your electricity bills with ease using the TekPay App.</p>
      </div>
    </div>
    <div class="service-item">
      <img src="../assets/icons/data.png" alt="Data Bundle Topup">
      <div class="service-text">
        <h3>Data Bundle Topup</h3>
        <p>Stay connected to the internet no matter where you are with TekPay's easy data bundle top-up service.</p>
      </div>
    </div>
    <div class="service-item">
      <img src="../assets/icons/internet.png" alt="Internet Topup">
      <div class="service-text">
        <h3>Internet Topup</h3>
        <p>Whether you need internet for work, study, or entertainment, TekPay makes it easy to top up your internet and stay connected.</p>
      </div>
    </div>
    <div class="service-item">
      <img src="../assets/icons/cable-tv.png" alt="Cable TV Subscription">
      <div class="service-text">
        <h3>Cable TV Subscription</h3>
        <p>Get uninterrupted access to your favorite TV shows and channels by subscribing to your cable TV with TekPay.</p>
      </div>
    </div>
    <div class="service-item">
      <img src="../assets/icons/betting.png" alt="Betting">
      <div class="service-text">
        <h3>Betting</h3>
        <p>Feeling lucky? Bet on your favorite sports games or events with TekPay and enjoy the thrill of the game.</p>
      </div>
    </div>
  </div>
</div>

<section class="download-steps">
  <div class="steps-container">
    <div class="steps-image">
      <img src="../assets/steps.png" alt="App download steps">
    </div>
    <div class="steps-content">
      <h2>3 Simple Steps to Enjoy <span class="name">TekPay.</span></h2>
      <div class="step">
        <h3><span class="num">1</span> Download and install the App</h3>
        <p>Visit your app store, search for “TekPay” and download and install the app on your mobile device.</p>
      </div>
      <div class="step">
        <h3><span class="num">2</span> Signup and Onboarding</h3>
        <p>Open the app and follow the quick and easy sign-up process. All you need is your basic personal information.</p>
      </div>
      <div class="step">
        <h3><span class="num">3</span> Add funds and pay bills</h3>
        <p>Once you’re signed in you can add funds to your account and start paying your bills. It's that simple!</p>
      </div>
      <button class="cta-button">Download App</button>
    </div>
  </div>
</section>

<section class="faq-section">
    <div class="faq-container">
      <div class="faq-image">
        <img src="../assets/faq.png" alt="FAQ Image">
        <span class="chat-image"><img src="../assets/chat.png"  alt="Chat Image"></span>
        <span class="chatbtn-image"><img src="../assets/chatbtn.png" alt="Chatbtn Image"></span>
      </div>

      <div class="faq-content">
        <div class="faq-heading">
          <p>faq</p>
          <h2>How can we help you?</h2>
        </div>
        <div class="faq-item" v-for="(faq, index) in faqs" :key="index">
          <button class="faq-question" @click="toggleFaq(index)" :aria-expanded="faqOpen === index">
            {{ faq.question }}
            <span :class="{ 'rotated': faqOpen === index }">&#9662;</span>
          </button>
          <div class="faq-answer" v-show="faqOpen === index">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>


</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      faqOpen: null,
      faqs: [
        {
          question: 'What is TekPay?',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam interdum eros at erat dapibus, a tincidunt libero egestas.'
        },
        {
          question: 'How do I sign up for TekPay?',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam interdum eros at erat dapibus, a tincidunt libero egestas.'
        },
        {
          question: 'How do I add funds to my account?',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam interdum eros at erat dapibus, a tincidunt libero egestas.'
        },
        {
          question: 'Is TekPay secure?',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam interdum eros at erat dapibus, a tincidunt libero egestas.'
        },
        {
          question: 'Can I use TekPay internationally?',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam interdum eros at erat dapibus, a tincidunt libero egestas.'
        }
      ]
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    toggleFaq(index) {
      this.faqOpen = this.faqOpen === index ? null : index;
    }
  }
}
</script>





<style scoped>

/* Reset default margin and padding for all elements */
body, html *{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.hero {
  background-color: #9333EA;
  color: #fff;
  padding: 10px 20px;
  margin: 0;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/pattern.png");
  background-repeat: repeat;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  padding: 15px 40px;
  max-width: 1000px;
  margin: 0 auto;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0 150px;
  display: flex;
}

.nav-links li {
  display: inline-block;
  margin-right: 20px;
}

.nav-links li a {
  color: #333;
  font-weight: 500;
  text-decoration: none;
}

.download-btn {
  background-color: #fff;
  color: #9333EA;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

.logo {
  max-height: 50px;
  margin-right: 50px;
  padding-left: 30px;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.mobile-nav {
  display: none;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  top: 70px;
  width: 100%;
  z-index: 1000;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.mobile-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav ul li {
  padding: 15px 20px;
  border-bottom: 1px solid #ccc;
}

.mobile-nav ul li a {
  color: #333;
  text-decoration: none;
}

.mobile-nav.open {
  display: flex;
  max-height: 400px;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.hero-content-container {
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 180px;
}

.text-content {
  flex: 1;
  text-align: left;
}

.text-content h1 {
  font-size: 66px;
  margin-bottom: 10px;
  width: 70%;
}

.text-content p {
  font-size: 25px;
  line-height: 1.6;
  font-weight: 400;
  margin-bottom: 20px;
}

.first-text {
  margin-top: 20px;
  display: flex;
}

.bordered-text {
  padding: 10px 30px;
  border-radius: 25px;
  background-color: #fff;
  border: 2px solid #fff;
  color: #9333EA;
}

.second-border {
  color: #fff;
  background-color: #9333EA;
  border-radius: 25px;
  padding: 5px 10px;
}

.download-buttons {
  margin-top: 20px;
}

.download-buttons a {
  display: inline-block;
}

.app-store-badge {
  width: 170px;
  height: 80px;
}

.google-play-badge {
  width: 170px;
  height: 80px;
}

.main-hero-image {
  position: relative;
  top: 20%;
  right: 20%;
  bottom: 0;
  left: -20%;
  transform: translateX(50%);
  width: auto;
  height: 100%;
  z-index: -0.5;
}

.secondary-hero-image {
  position: absolute;
  bottom: 10px;
  top: 70%;
  right: 400px;
}

.brands-section {
  text-align: center;
  padding: 50px 150px;
  background-color: #fff;
  color: #333;
}

.brands-section h2 {
  font-size: 36px;
  margin-bottom: 5px;
  font-weight: bolder;
}

.brands-section h2 .number {
  color: #9333EA;
  font-size: 40px;
}

.brands-section h2 .h2br {
  flex-direction: column;
  line-break: strict;
  font-size: 50px;
}

.brands-section h2 .tekpay {
  color: #9333EA;
}

.brands-section p {
  font-size: 18px;
  margin-bottom: 60px;
}

.brand-image-banner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-image-banner img {
  max-width: 100%;
  height: auto;
}

.services-section {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 80px;
  background-color: var(--Primary-Light-50, #FBF6FF);
}

.column-a {
  flex: 1;
  padding-right: 20px;
}

.column-a h2 {
  font-size: 32px;
  margin-bottom: 10px;
  text-align: left;
}

.column-a p {
  font-size: 15px;
  line-height: 1.6;
  text-align: left;
}

.column-b {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.service-item {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(50% - 100px); 
  align-items: center;
}

.service-item img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.service-text h3 {
  font-size: 20px;
  margin-bottom: 5px;
  text-align: left;
}

.service-text p {
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
}

.download-steps {
  padding: 50px 20px;
  background-color: #fff;
}

.steps-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.steps-image {
  flex: 1;
  text-align: center;
}

.steps-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.steps-content {
  flex: 1;
  padding: 20px;
}

.steps-content h2 {
  font-size: 42px;
  margin-bottom: 20px;
  text-align: left;
}

.steps-content h2 .name {
  color: #9333EA;
}

.step {
  margin-bottom: 20px;
  text-align: left;
}

.num {
  border: solid 1px #9333EA ;
  padding: 5px 15px;
  border-radius: 100px;
}

.step h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.step p {
  font-size: 16px;
  line-height: 1.6;
}

.cta-button {
  background-color: #9333EA;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.cta-button:hover {
  background-color: #751acb;
}

.faq-section {
  padding: 50px 20px;
  background-color: #FAFAFB;
}

.faq-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: flex-start;
}

.faq-image {
  flex: 1;
  text-align: center;
}

.faq-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
.chat-image img {
  position: absolute;
  top: 385%;
  left: 180px;
  bottom: 10%;
}
.chatbtn-image img {
  position: absolute;
  top: 430%;
  left: 500px;
  bottom: 10%;
}

.faq-content {
  flex: 1;
  padding: 20px;
}

.faq-heading p {
  color: #9333EA;
  font-size: 14px;
  text-transform: uppercase;
  text-align:  left;
  margin-bottom: -25px;
}

.faq-heading h2 {
  font-size: 35px;
  margin-bottom: 25px;
  text-align: left;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  background: none;
  border: none;
  font-size: 18px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question span.rotated {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 0.5rem;
  border-top: 1px solid #ddd;
  text-align: left;
}

.rotated {
  transform: rotate(180deg);
}


/* Responsive styles for mobile */
@media (max-width: 768px) {
  body, html *{
    overflow-x: hidden;
    overflow-y: hidden;
    width: auto;
  }
  .hero {
    height: 180vh;
  }
  .navbar {
    background-color: #fff;
    border-radius: 50px;
    padding: 5px 25px;
    max-width: 1000px;
    margin: 0 auto; 
    position:relative;
  }

  .nav-links {
    display: none;
  }

  .download-btn {
    visibility: hidden;
  }

  .mobile-menu-icon {
    display: block;
    color: #9333EA;
  }

  .mobile-nav{
    border-radius: 20px;
    width: 80%;
    margin-left: 30px;
  } 

  .hero-content-container {
    flex-direction: column;
    text-align: center;
    margin-left: -10px;
  }

  .bordered-text {
    margin-left: 20px;
  }

  .text-content {
    width: 90%;
    text-align: center;
  }

  .text-content h1 {
    width: 100%;
    font-size: 32px;
  }
  .text-content p {
    font-weight: 400;
    word-spacing: 3;
    font-size: 22px;
  }

  .main-hero-image {
    position: static;
    transform: none;
    width: 100%;
    height: auto;
    order: -1;
  }

  .secondary-hero-image {
    display: none;
  }

  .download-buttons {
    margin-right: 10px;
    width: auto;
  }

  .brands-section {
    text-align: center;
    padding: 10px 10px;
    background-color: #fff;
    color: #333;
  }

  .brands-section h2 {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .brands-section p {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 35px;
  }

  .brands-section h2 .number {
    color: #9333EA;
    font-size: 20px;
  }

  .brands-section h2 .h2br {
    flex-direction: column;
    line-break: strict;
    font-size: 23px;
  }

  .brands-section h2 .tekpay {
    color: #9333EA;
    text-decoration: underline;
  }

  .brand-image-banner img {
    width: 80%;
    margin-bottom: 20px;
  }

  .services-section {
    flex-direction: column;
  }

  .column-a, .column-b {
    width: 100%;
    margin-right: 10px;
  }

  .column-a {
    padding-right: 0;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 20px;
  }

  .column-a h2 {
    text-align: center;
  }

  .column-a p {
    text-align: center;
    font-size: 12px;
  }

  .service-item {
    width: 150%;
  }

  .service-item img {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }

  .service-text h3 {
    font-size: 15px;
  }

  .service-text p {
    font-size: 10px;
  }
  .steps-container {
    flex-direction: column;
    text-align: center;
  }

  .steps-image img {
  visibility: hidden;
}

  .steps-content {
    padding: 0;
    order: -1;
  }

  .steps-content h2 {
    font-size: 24px;
    padding: 5px 10px;
    text-align: center;
  }

  .step h3 {
    font-size: 20px;
    padding: 5px 10px;
  }

  .num {
    margin-right: 10px;

  }

  .step p {
    font-size: 14px;
    padding: 5px 20px;
  }

  .cta-button {
    width: 50%;
    padding: 15px;
  }

  .faq-container {
    flex-direction: column;
    text-align: center;
    margin-top: -110%;
  }

  .faq-content {
    padding: 0;
    order: -1;
  }

  .faq-image {
    visibility: hidden;
  }
  .chat-image img {
    display: none;
  }

  .chatbtn-image img {
    display: none;
  }

  .faq-heading p {
    text-align: center;
  }

  .faq-heading h2 {
    font-size: 24px;
    padding: 5px 50px;
    text-align: center;
  }

  .faq-question {
    font-size: 16px;
    padding: 5px 40px;
    color: #000;
  }

  .faq-answer {
    font-size: 14px;
    color: #9333EA;
    padding: 0 50px;
    text-align: justify;
  }
}
@media (max-width: 480px){
  body, html *{
    overflow-x: hidden;
    overflow-y: hidden;
    width: auto;
  }
  .step h3 {
    font-size: 18px;
    padding: 5px 20px;
  }

  .step p {
    font-size: 14px;
    padding: 5px 40px;
    text-align: justify;
    padding-left: 70px;
  }

  .cta-button {
    width: 50%;
    padding: 15px 10px;
    margin-top: 25px;
  }
}
</style>


