<template>
    <footer class="footer">
      <div class="footer-container">
        <div class="footer-column about">
          <img src="../assets/Logo-2.png" alt="TekPay Logo" class="footer-logo" />
          <p>TekPay is a leading payment service provider that enables users to easily and securely pay for various bills and subscriptions, including electricity, internet, airtime, data bundles, education bills, cable TV, and more.</p>
        </div>
        <div class="footer-column services">
          <h3>Services</h3>
          <ul>
            <li>Airtime</li>
            <li>Electricity</li>
            <li>Data Bundles</li>
            <li>Internet</li>
            <li>Cable TV</li>
            <li>Education Bills</li>
            <li>Betting</li>
          </ul>
        </div>
        <div class="footer-column useful-links">
          <h3>Useful Links</h3>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#contact">Contact</a></li>
            <li><a href="#faq">FAQ</a></li>
          </ul>
        </div>
        <div class="footer-column contact">
          <h3>Contact</h3>
          <p>5007 Ocean Avenue, Lagos, NG</p>
          <p>Phone: +234 123 456 7890</p>
          <p>Email: info@tekpay.ng</p>
        </div>
      </div>
      <div class="footer-bar"> 
        <div class="social-icons">
          <a href="#"><img src="@/assets/icons/facebook.png" alt="Facebook" /></a>
          <a href="#"><img src="@/assets/icons/twitter.png" alt="Twitter" /></a>
          <a href="#"><img src="@/assets/icons/linkedin.png" alt="LinkedIn" /></a>
          <a href="#"><img src="@/assets/icons/instagram.png" alt="Instagram" /></a>
        </div>
        <p>&copy; 2024 TekPay. All rights reserved.</p>
      </div>
    </footer>
  </template>
  
  <style>
  .footer {
    background-color: #1E2128;
    padding: 50px 10px;
    border-radius: 100px 70px 0 0;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    min-width: 200px;
    margin-bottom: 10px;
  }
  
  .footer-logo {
    width: 80px;
    margin-bottom: 10px;
    position: absolute;
    left: 45px;
    top: 465%;
  }
  
  .footer-column h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #f6f6f6;
  }
  
  .footer-column p, .footer-column ul, .footer-column li, .footer-column a {
    font-size: 1rem;
    color: #f6f6f6;
    line-height: 1.5;
    text-decoration: none;
    text-align: center;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column li {
    margin-bottom: 5px;
  }
  
  .footer-column a:hover {
    text-decoration: underline;
  }

  .footer-bar {
  background-color: #1E2128;
  padding: 2px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f6f6f6;
  margin-bottom: -50px;
}

.social-icons {
  margin-bottom: 2px;
  margin-top: 10px;
}

.social-icons a {
  margin: 0 10px;
  display: inline-block;
}

.social-icons img {
  width: 24px;
  height: 24px;
}
  
  @media (max-width: 768px) {
    .footer {
      margin-top: -600px;
      padding: 80px 10px; 
    }
    .footer-container {
      flex-direction: column;
      align-items: center;
    }

    .footer-logo {
      width: 60px;
      margin-bottom: 10px;
      position: absolute;
      left: 155px;
      top: 586%;
    }
  
    .footer-column {
      max-width: 100%;
      text-align: center;
    }
    .footer-column p{
      padding: 5px 20px;
      text-align: center;
    }
  }

  @media (max-width: 480px) {
    .footer-logo {
      top: 469%;
      left: 170px;
    }
  }
  
 
  </style>
  
  <script>
  export default {
    name: 'FooterSection'
  }
  </script>
  